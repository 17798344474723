(function (d, w) {

  const nav = d.querySelector('.nav_primary')
  const topNavs = nav.querySelectorAll('.topnav > li > a')
  const menu = nav.querySelector('.topnav')
  // const sublinks = menu.querySelectorAll('.sub a')
  const hrefs = menu.querySelectorAll('a')
  const hrefsArr = Array.prototype.slice.call(hrefs)
  // const lastNavLi = nav.querySelector('.topnav > li:last-child')
  const bttn = d.getElementById('bttn-nav')
  const sinput = d.getElementById('search')
  const sfoc = d.getElementById('srchfoc')
  const sbttn = nav.querySelector('.search__toggle')
  const sform = nav.querySelector('.search')
  const forEach = [].forEach
  // const xlinks = [...d.getElementsByTagName('a')].filter(el => el.hostname && el.hostname !== window.location.hostname)
  const xlinks = Array.prototype.slice.call(d.getElementsByTagName('a')).filter(el => el.hostname && el.hostname !== window.location.hostname)

  menu.hidden = true

  xlinks.forEach(el => {
    el.setAttribute('rel', 'noopener noreferrer')
    el.setAttribute('target', '_blank')
  })

  /*
  console.log(nav);
  console.log(bttn);
  console.log(menu);
  console.log(topNavs);
  console.log(sublinks);
  console.log(hrefs);
  console.log(hrefsArr);
  console.log(lastNavLi);
  */

  /** Initial SETUP
   * hide expansion attribute, button and menu
   * add a "last" class to the top-level nav
   *
   */
  /*
  bttn.setAttribute('aria-expanded', 'false');
  bttn.hidden = false;
  menu.hidden = true;
  lastNavLi.classList.add('last');
  */

  const xAllSubs = () => {

    const subs = menu.querySelectorAll('.sub')
    setSubNavLinksTabIndex()

    forEach.call(subs, el => {
      el.setAttribute('aria-hidden', 'true')
    })

    setSubNavLinksTabIndex()
    /*
    sbttn.setAttribute('aria-expanded', 'false')
    sform.classList.remove('show')
    sinput.blur()
    */
  }

  /*
  const dupeParentLink = (el, subNav) => {
    // if (!subNav.querySelector('.added')) {
    let dupe_li = d.createElement('li')
    // dupe_li.classList.add('added')
    let dupe_a = d.createElement('a')
    dupe_a.href = el.href
    dupe_a.setAttribute('tabindex', '')
    dupe_a.textContent = el.dataset.headline
    dupe_li.appendChild(dupe_a)
    subNav.insertBefore(dupe_li, subNav.firstChild)
    // }
  }
  */

  /*
  const removeDupe = sn => {
    const added = sn.firstChild;
    added.parentNode.removeChild(added);
  }
  */

  /**
   * SETUP FUNCTION:
   * add a hassub class to hrefs that have a sub menu following them.
   * add an id to each sub menu
   * hide the sub menus
   * remove "hidden" after page load
   */
  forEach.call(hrefs, (el) => {
    if (el.nextElementSibling) {
      let subId = 'sm' + hrefsArr.indexOf(el)
      el.setAttribute('aria-haspopup', true)
      el.setAttribute('aria-controls', subId)
      let sub = el.nextElementSibling
      if (sub.nodeName === 'UL') {
        // el.classList.add('hassub');
        // dupeParentLink(el, sub)
        sub.setAttribute('aria-hidden', true)
        sub.hidden = false
        sub.id = subId
      }
    }
    if (String(w.location) === el.href) {
      el.setAttribute('aria-current', 'page')
    }
    /*
    let parid = el.dataset.par;
    if (el.getAttribute('aria-current') === 'page' && parid > 0) {
      let pgpar = d.getElementById('pg' + parid);
      if (pgpar.getAttribute('aria-current') !== 'location') {
        pgpar.setAttribute('aria-current', 'location');
      }
    }
    */
  })

  /**
   * removes an opnd class from all top-level links
   */
  /*
  var removeOpenedClass = () => {
    forEach.call(topNavs, (el) => {
      if (el.classList.contains('opnd') === true) {
        el.classList.remove('opnd');
      }
    })
  }
  */

  /**
   * sets tabindex to -1 on all subnav links.
   */
  const setSubNavLinksTabIndex = () => {
    const sublnks = menu.querySelectorAll('.sub a')
    forEach.call(sublnks, (el) => {
      el.setAttribute('tabindex', '-1')
    })
  }

  /** SETUP:
   * run a function that will set the tabindex to -1 on all subnav links.
   */
  setSubNavLinksTabIndex()

  /**
   * add event listener to all top-level navigation links that will:
   * toggle all hidden attributes and
   * reset the tabindex for that subnav
   */
  forEach.call(topNavs, (el) => {
    if (el.getAttribute('aria-haspopup') === 'true') {
      el.addEventListener('click', e => {
        e.preventDefault()
        // const li = el.parentNode
        const subnav = el.nextElementSibling
        const visible = subnav.getAttribute('aria-hidden') === 'false'
        // const headline = el.dataset.headline
        // console.log(headline)
        if (visible) {
          // removeDupe(subnav);
          subnav.setAttribute('aria-hidden', 'true')
          setSubNavLinksTabIndex()
        } else {
          xAllSubs()
          subnav.setAttribute('aria-hidden', 'false')
          // dupeParentLink(el, subnav)
          setSubNavFocus(subnav.id)
          // close the search if opeeports and Data
          sbttn.setAttribute('aria-expanded', 'false')
          sform.classList.remove('show')
          sinput.blur()
        }
        fstlst(el)
      }, false)
    }
  })

  /**
   * bind keyboard events for each subnav
   */
  const fstlst = el => {
    const links = Array.prototype.slice.call(d.getElementById(el.getAttribute('aria-controls')).querySelectorAll('a'))

    if (links) {
      let lnks = {
        'fst': links[0],
        'lst': links[links.length - 1]
      }

      lnks.fst.addEventListener('keydown', e => {

        if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault()
          el.focus()
        }
      })
      lnks.lst.addEventListener('keydown', e => {

        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault()
          el.focus()
        }
      })
    }
  }
  /**
   * hide all opened subnavs.
   * reset tabindex to -1 on all subnav links.
   * set an empty tabindex for all links in the subnav that has just been opened.
   * set focus on that subnav.
   */
  var setSubNavFocus = (id) => {
    if (id) {
      let sn = d.getElementById(id)
      let subNavLinks = sn.querySelectorAll('a')
      forEach.call(subNavLinks, (snl) => {
        snl.setAttribute('tabindex', '')
      })
      sn.focus()
    }
  }

  /**
   * hides all subnavs
   * called from setSubNavFocus function
   */
  /*
  var hideSubNavs = () => {
    let submenus = menu.querySelectorAll('.submenu')
    forEach.call(submenus, (el) => {
      // el.hidden = true
      el.setAttribute('aria-hidden', 'true')
    })
  }
  */

  /**
   * get first and last navigation links.
   * used to send focus back to the toggle button after tabbing through the navigation list
   * return an object containing fn.first, fn.last
   */
  var getFirstAndLastNavLinks = () => {
    let topLinks = Array.prototype.slice.call(topNavs)
    let lastLink = topLinks.length - 1
    return {
      'first': topLinks[0],
      'last': topLinks[lastLink]
    }
  }

  /**
   * SETUP function
   * adds an event listener to the navigation toggle button
   */
  bttn.addEventListener('click', () => {
    let expanded = bttn.getAttribute('aria-expanded') === 'true'
    let menu = d.getElementById('topnav')
    bttn.setAttribute('aria-expanded', String(!expanded))
    menu.hidden = expanded
    if (!expanded) {
      menu.focus()
      let fl = getFirstAndLastNavLinks()
      // console.log(fl.last);
      fl.last.addEventListener('keydown', (f) => {
        let isOpen = fl.last.nextElementSibling.getAttribute('aria-hidden') === 'false'
        if (f.keyCode === 9 && !f.shiftKey && !isOpen) {
          f.preventDefault()
          bttn.focus()
        }
      })
      fl.first.addEventListener('keydown', (f) => {
        if (f.keyCode === 9 && f.shiftKey) {
          f.preventDefault()
          bttn.focus()
        }
      })
    }
  }, false)

  /**
   * SETUP function
   * adds an event listener to the "skip to search" link
   */
  sfoc.addEventListener('click', e => {
    let expanded = sbttn.getAttribute('aria-expanded') === 'true'
    sbttn.setAttribute('aria-expanded', String(!expanded))
    if (!expanded) {
      sform.classList.add('show')
    }
    sinput.focus()
    xAllSubs()
    e.preventDefault()
  }, false)

  /**
   * SETUP function
   * adds an event listener to the search toggle button
   */
  sbttn.addEventListener('click', () => {
    let expanded = sbttn.getAttribute('aria-expanded') === 'true'
    sbttn.setAttribute('aria-expanded', String(!expanded))
    if (!expanded) {
      sform.classList.add('show')
      sinput.focus()
    } else {
      sform.classList.remove('show')
      sinput.blur()
    }
    xAllSubs()
  }, false)

})(document, window)
